
import { computed, defineComponent, onBeforeMount, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useTitle } from 'vue-composable'

// components
import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'

// module components
import LTLReceiver from '@/modules/CreateOrder/components/LTLReceiver/index.vue'
import LTLCargoPayment from '@/modules/CreateOrder/components/LTLCargoPayment/index.vue'
import NewReceiverCreate from '@/modules/CreateOrder/components/NewReceiverCreate/index.vue'

// types
import { LTLFullInvoiceT, LTLOrderInvoiceCargoAndDeliveryT } from '@/core/types/LTL.types'
import { ReceiverT } from '@/core/types/common.types'
import { ReceiverCreatePayload } from '@/core/types/Order.types'

// realisations
import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'

// notifications
import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

// constants
import {
    SHIPMENT_TYPE_OPTIONS,
    PAYMENT_TYPE_OPTIONS,
    PAYMENT_METHOD_OPTIONS,
} from '@/core/constants/common.constants'

// composable
import {
    isReceiverActionsOpened,
    useMembersActions,
} from '@/modules/CreateOrder/composable/useMembersActions'
import { UpdateInvoicePayloadT } from '@/core/types/Invoices.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        's-button': SButton,
        's-modal': SModal,
        //
        'ltl-receiver': LTLReceiver,
        'ltl-cargo-payment': LTLCargoPayment,
        'receiver-create': NewReceiverCreate,
    },
    setup() {
        useTitle('Редактирование накладной')

        const route = useRoute()
        const router = useRouter()

        const invoice_id = route.params.invoice_id as string

        const notifications = useNotifications()

        const { closeReceiverActions, startEditReceiver, updateReceiver } = useMembersActions()

        const state = reactive({
            is_loading: false,
            is_failed: false,
            is_loaded: false,
            invoice: null as LTLFullInvoiceT | null,
            updating_invoice: null as any | null,
            is_submiting: false,
        })

        const invoicesRealisation = new InvoicesRealisation()

        const isHasAdditionalService = (type_code: string) => {
            if (!state.invoice) return false
            return state.invoice.additional_services_values.some(
                (service) => service.type_code === type_code
            )
        }

        const initializeAdditionalServices = () => {
            if (!state.updating_invoice || !state.invoice) return

            if (state.invoice.additional_services_values.length > 0) {
                state.updating_invoice.additional_services.hasGrid = isHasAdditionalService('grid')
                state.updating_invoice.additional_services.hasPallet =
                    isHasAdditionalService('pallet')
                state.updating_invoice.additional_services.hasSoftPackage =
                    isHasAdditionalService('soft_package')
            } else if (state.invoice.additional_services) {
                state.updating_invoice.additional_services.hasGrid =
                    state.invoice.additional_services.grid
                state.updating_invoice.additional_services.hasPallet =
                    state.invoice.additional_services.pallet
                state.updating_invoice.additional_services.hasSoftPackage =
                    state.invoice.additional_services.soft_package
            }
        }

        const initializeCargo = () => {
            if (!state.invoice) return

            state.updating_invoice = {
                cargo: {
                    product_name: state.invoice.product_name,
                    customerInvoiceNumber: state.invoice.dop_invoice_number,
                    weight: state.invoice.weight,
                    places: state.invoice.places,
                    length: state.invoice.depth,
                    width: state.invoice.width,
                    height: state.invoice.height,
                    annotation: state.invoice.annotation,
                    isReturnDocuments: Boolean(state.invoice.should_return_document),
                    //
                    is_dangerous: state.invoice.is_dangerous,
                    invoice_files: state.invoice.invoice_files,
                    certificate_of_safety_files: state.invoice.certificate_of_safety_files,
                    temperature_regime: state.invoice.temperature_regime,
                },
                delivery: {
                    codPayment: state.invoice.cod_payment,
                    cargo_name: state.invoice.cargo_name,
                    declaredPrice: state.invoice.declared_price,
                    shipmentType: null,
                    paymentType: null,
                    paymentMethod: null,
                    payer_company_id: state.invoice.payer_company_id,
                },
                additional_services: {
                    hasGrid: false,
                    hasPallet: false,
                    hasSoftPackage: false,
                },
                verify: state.invoice.verify,
            }

            const shipment_type = (state.invoice as LTLFullInvoiceT).shipment_type_id
            const payment_type = (state.invoice as LTLFullInvoiceT).payment_type_id
            const payment_method = (state.invoice as LTLFullInvoiceT).payment_method_id

            state.updating_invoice.delivery.shipmentType = SHIPMENT_TYPE_OPTIONS.find(
                (option) => option.value === shipment_type
            )
            state.updating_invoice.delivery.paymentType = PAYMENT_TYPE_OPTIONS.find(
                (option) => option.value === payment_type
            )
            state.updating_invoice.delivery.paymentMethod = PAYMENT_METHOD_OPTIONS.find(
                (option) => option.value === payment_method
            )

            initializeAdditionalServices()
        }

        const loadInvoice = async () => {
            if (!invoice_id) return

            try {
                state.is_loading = true

                const response = await invoicesRealisation.getLTLInvoice(invoice_id)
                state.invoice = response.data

                initializeCargo()

                const response_receiver = { ...response.data.receiver }

                receivers_state.receiver = {
                    additional_phone: response_receiver.additional_phone,
                    id: response_receiver.id,
                    city_id: response_receiver.city_id,
                    comment: response_receiver.comment,
                    full_address: response_receiver.full_address,
                    full_name: response_receiver.full_name,
                    house: response_receiver.house,
                    index: response_receiver.index,
                    latitude: Number(response_receiver.latitude),
                    longitude: Number(response_receiver.longitude),
                    office: response_receiver.office,
                    phone: response_receiver.phone,
                    street: response_receiver.street,
                    title: response_receiver.title,
                    warehouse_id: response_receiver.warehouse_id
                        ? response_receiver.warehouse_id
                        : null,
                    distribution_center_id: response_receiver.distribution_center_id,
                }

                state.is_loaded = true
                state.is_failed = false
            } catch (error) {
                state.is_loaded = false
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const receivers_state = reactive({
            is_update: false,
            receiver: null as ReceiverT | null,
        })

        onBeforeMount(loadInvoice)

        const startUpdateReceiver = () => {
            startEditReceiver({ ...receivers_state.receiver } as ReceiverT)
        }

        const updateReceiverHandler = async (receiver_payload: ReceiverCreatePayload) => {
            if (!receiver_payload.id || !receivers_state.receiver) return

            try {
                await updateReceiver(receiver_payload.id, receiver_payload)

                receivers_state.receiver.additional_phone = receiver_payload.additional_phone
                receivers_state.receiver.city_id = receiver_payload.city.id
                receivers_state.receiver.longitude = receiver_payload.longitude
                receivers_state.receiver.latitude = receiver_payload.latitude
                receivers_state.receiver.office = receiver_payload.office
                receivers_state.receiver.phone = receiver_payload.phone
                receivers_state.receiver.street = receiver_payload.street
                receivers_state.receiver.title = receiver_payload.title
                receivers_state.receiver.warehouse_id = receiver_payload.warehouse_id
                receivers_state.receiver.comment = receiver_payload.comment
                receivers_state.receiver.distribution_center_id =
                    receiver_payload.distribution_center_id
                receivers_state.receiver.full_address = receiver_payload.full_address
                receivers_state.receiver.full_name = receiver_payload.full_name
                receivers_state.receiver.house = receiver_payload.house
                receivers_state.receiver.index = receiver_payload.index

                closeReceiverActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Пожалуйста, укажите адрес',
                    },
                    error
                )
            }
        }

        const pageTitle = computed(() => {
            if (!state.invoice) return 'Редактирование накладной'
            return `Редактирование накладной ${state.invoice.invoice_number}`
        })

        const senderCityId = computed(() => {
            if (state.invoice) return state.invoice.sender.city_id
            return null
        })

        const isOnlyAuto = computed(() => {
            if (!state.invoice) return false
            if (!receivers_state.receiver) return false

            if (state.invoice.sender.city_id === receivers_state.receiver.city_id) return true

            return false
        })

        const isPvz = computed(() => {
            if (!state.invoice) return false
            if (!receivers_state.receiver) return false

            return Boolean(receivers_state.receiver.distribution_center_id)
        })

        const getPayload = (invoice: LTLOrderInvoiceCargoAndDeliveryT): UpdateInvoicePayloadT => {
            const take_date = (state.invoice as LTLFullInvoiceT).take_date
            const period_id = (state.invoice as LTLFullInvoiceT).period_id

            const payload: UpdateInvoicePayloadT = {
                take_date,
                period_id,
                dop_invoice_number: invoice.cargo.customerInvoiceNumber,
                places: invoice.cargo.places,
                weight: invoice.cargo.weight,
                width: invoice.cargo.width,
                height: invoice.cargo.height,
                depth: invoice.cargo.length,
                annotation: invoice.cargo.annotation,
                shipment_type: invoice.delivery.shipmentType.value,
                payment_type: invoice.delivery.paymentType.value,
                payment_method: invoice.delivery.paymentMethod.value,
                payer_company_id: invoice.delivery.payer_company_id as number | null,
                cod_payment: invoice.delivery.codPayment,
                declared_price: invoice.delivery.declaredPrice,
                should_return_document: Number(invoice.cargo.isReturnDocuments),
                verify: invoice.verify,
                volume: invoice.cargo.volume,
                //
                is_dangerous: invoice.cargo.is_dangerous,
                invoice_files: invoice.cargo.invoice_files as string[],
                certificate_of_safety_files: invoice.cargo.certificate_of_safety_files as string[],
            }

            return payload
        }

        const submitInvoice = async (invoice: LTLOrderInvoiceCargoAndDeliveryT) => {
            if (state.is_submiting) return

            if (!receivers_state.receiver) {
                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите получателя',
                })

                return
            }

            try {
                state.is_submiting = true

                const payload = getPayload(invoice)
                await invoicesRealisation.updateInvoice(Number(invoice_id), payload)

                notifications.success({
                    title: 'Накладная сохранена',
                    type: 'success',
                    group: NOTIFICATIONS_GROUP,
                })
                router.push('/history')
            } catch (error) {
                console.error(error)

                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка сохранения накладной',
                    },
                    error
                )
            } finally {
                state.is_submiting = false
            }
        }

        const isCanUpdateCargo = computed(() => {
            if (!state.invoice || !state.invoice.status) return false

            /* До обработки */
            return (
                state.invoice.status.id === 1 ||
                state.invoice.status.id === 2 ||
                state.invoice.status.id === 4
            )
        })

        const isCanUpdateReceiver = () => {
            if (!state.invoice) return false
            if (!state.invoice.status) return false

            /* До обработки */
            if (
                state.invoice.status.code === 201 ||
                state.invoice.status.code === 202 ||
                state.invoice.status.code === 203 ||
                state.invoice.status.code === 225
            ) {
                return true
            }

            /* До выдачи */
            if (
                state.invoice.status.code === 201 ||
                state.invoice.status.code === 202 ||
                state.invoice.status.code === 203 ||
                state.invoice.status.code === 225 ||
                state.invoice.status.code === 205 ||
                state.invoice.status.code === 206 ||
                state.invoice.status.code === 208
            ) {
                return true
            }

            return false
        }

        return {
            state,
            receivers_state,
            loadInvoice,
            pageTitle,
            senderCityId,
            isOnlyAuto,
            isPvz,
            submitInvoice,
            isReceiverActionsOpened,
            closeReceiverActions,
            updateReceiverHandler,
            startUpdateReceiver,
            isCanUpdateCargo,
            isCanUpdateReceiver,
        }
    },
})
